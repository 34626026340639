<template>
  <section class="alianzas-section py-5">
    <div class="container-fluid">
      <h2 class="text-center mb-4 fw-bold text-uppercase" style="color: var(--color-pr);">Nuestras Alianzas Estratégicas</h2>
      <p>El trabajo del Colegio es posible gracias al compromiso de nuestros padres de familias y nuestro socio estratégico  <strong>WORLD VISIÓN</strong> quienes permiten la ejecución de este proyecto y aportan en la parte presupuestal para que se ejecute esta institución</p>
      <div class="alianzas-slider">
        <div 
          class="alianzas-track" 
          :style="{ transform: `translateX(-${currentTranslate}px)` }"
        >
          <div 
            class="alianza-item" 
            v-for="(alianza, index) in duplicarAlianzas" 
            :key="index"
          >
            <img :src="alianza.logo" :alt="alianza.nombre" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'AlianzaSection',
  setup() {
    const alianzas = ref([
      { nombre: 'World Visión', logo: require('@/assets/LogoConvenios/world_vision.png') },
      { nombre: 'Universidad Cordoba', logo: require('@/assets/LogoConvenios/Universidad_Cordoba.png') },
      { nombre: 'UPB', logo: require('@/assets/LogoConvenios/UPB.png') },
      { nombre: 'CENSA', logo: require('@/assets/LogoConvenios/CEnsa.jpg') },
      { nombre: 'Americana', logo: require('@/assets/LogoConvenios/Americana.jpg') },
      { nombre: 'Sena', logo: require('@/assets/LogoConvenios/Sena.png') },
      // Agrega más alianzas según necesites
    ]);

    // Duplicar el arreglo para lograr un scroll infinito
    const duplicarAlianzas = ref([...alianzas.value, ...alianzas.value]);

    const currentTranslate = ref(0);
    const speed = 0.3; // Velocidad del desplazamiento
    let animationFrame;

    const animate = () => {
      currentTranslate.value += speed;

      // Resetear la posición de desplazamiento cuando alcanza la mitad
      if (currentTranslate.value >= alianzas.value.length * 180) { // 180 es el ancho ajustado
        currentTranslate.value = 0; // Volver al inicio sin transición brusca
      }

      animationFrame = requestAnimationFrame(animate);
    };

    onMounted(() => {
      animate();
    });

    onUnmounted(() => {
      cancelAnimationFrame(animationFrame);
    });

    return {
      duplicarAlianzas,
      currentTranslate,
    };
  },
};
</script>

<style scoped>
.alianzas-section {
  background-color: #f8f9fa;
}

.alianzas-slider {
  overflow: hidden;
  white-space: nowrap;
}

.alianzas-track {
  display: flex;
  width: max-content;
  transition: transform 0.1s linear;
}

.alianza-item {
  flex: 0 0 auto;
  width: 180px; /* Ancho por defecto */
  margin-right: 30px; /* Espacio entre logos */
  display: flex;
  align-items: center;
  justify-content: center;
}

.alianza-item img {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
}

/* Responsividad */

/* Dispositivos pequeños (móviles) */
@media (max-width: 576px) {
  .alianza-item {
    width: 120px;
    margin-right: 20px;
  }

  .alianza-item img {
    max-height: 80px;
  }
}

/* Dispositivos medianos (tablets) */
@media (min-width: 577px) and (max-width: 992px) {
  .alianza-item {
    width: 150px;
    margin-right: 25px;
  }

  .alianza-item img {
    max-height: 90px;
  }
}

/* Dispositivos grandes (escritorio) */
@media (min-width: 993px) {
  .alianza-item {
    width: 180px;
    margin-right: 30px;
  }

  .alianza-item img {
    max-height: 100px;
  }
}
</style>