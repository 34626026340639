<template>
    <div class="container">
      <!-- Sección de Reseña Histórica -->
      <section class="history">
        <h2>RESEÑA HISTÓRICA</h2>
        
        <p>
          El Colegio Visión Mundial (antes Institución Educativa Asodesi) inició su construcción en abril del año 2000, en el barrio 6 de Marzo, este gran proyecto se visiona como uno de los programas cumbres de la Organización Visión Mundial, siendo el único colegio en el mundo de esta ONG. Para el 18 de febrero del año 2002 se programa la inauguración del centro educativo y el 19 de ese mes se inician las actividades, cuando la institución contaba solo con tres aulas y el restaurante escolar, y no tenía servicio de fluido eléctrico, pese a esto y a otros obstáculos ASODESI era una realidad.
        </p>
        
        <!-- Imagen representativa 1 -->
        <img src="@/assets/imgColvm/img (1).jpg" alt="Inauguración del colegio en 2002">
        
        <p>
          El colegio contaba solo con educación preescolar, el acceso solo era para niños patrocinados por la O.N.G y el horario era mañana y tarde. Para el año 2003 se abre el ciclo básico primaria, para el año 2004 se abre la básica secundaria. Todo este proceso incluyó programas musicales, como (Batuta), programas alimenticios con el (I.C.B.F) y espirituales (Devocionales) para el bienestar integral de los educandos, proceso liderado por el rector Rafael Vega Hernández licenciado en humanidades.
        </p>
  
        <p>
          En ese contexto, en el año 2002 la organización Visión Mundial, inicia la operación del colegio ASODESI, en la ciudad de Montería. Este proyecto, desde esta óptica, se planteó como una estrategia educativa a largo plazo, que pudiera consolidar una experiencia educativa exitosa en comunidades en condiciones de pobreza y vulnerabilidad que pudiera ser replicable, en la región y el país.
        </p>
  
        <!-- Imagen representativa 2 -->
        <img src="@/assets/imgColvm/img (1).jpg" alt="Comunidad Educativa del Colegio Visión Mundial">
        
        <p>
          La institución históricamente ha venido aumentando la población atendida, actualmente brinda el servicio educativo a 615 estudiantes de estratos 0, 1 y 2 donde en el presente año estará sacando la cuarta promoción de bachilleres. Se encuentra clasificado en el Régimen Regulado, ubicado en la Categoría de desempeño SUPERIOR por sus resultados en las pruebas ICFES, a pesar de las dificultades socioeconómicas de los estudiantes. Igualmente ha obtenido reconocimientos por el excelente desempeño, en los aspectos deportivos, culturales y académicos. En estos diez años, se ha consolidado con compromiso en brindar educación y formación de calidad, donde con el excelente apoyo de los docentes, personal directivo y administrativo, los estudiantes, sus familias y comunidad son y serán transformados.
        </p>
  
        <p>
          En año 2010 el colegio logró mediante la resolución 0109 la aprobación para un BACHILLERATO TÉCNICO EN SISTEMAS, donde un grupo de 113 adolescentes y jóvenes han recibido el correspondiente título de formación técnica, titulación valiosa para el ingreso a la universidad y al mundo laboral, se trata por tanto, de una ventaja de calidad competitiva suficientemente en el mercado.
        </p>
        
        <!-- Imagen representativa 3 -->
        <img src="@/assets/imgColvm/img (1).jpg" alt="Bachillerato Técnico en Sistemas">
        
        <p>
          Somos una Institución que brinda una educación cristiana de calidad humanista en el Área de Sistemas, con manejo tecnológico, formando educandos competentes para la vida, capaces de contribuir al desarrollo local, regional y nacional, con capacitación especializada permanente y comprometida de los docentes y trabajadores de la Institución, con principios de identidad cultural y social, formando en valores por un nuevo ciudadano.
        </p>
  
        <p>
          En el año 2013, el Colegio de Visión Mundial alcanzó el Primer Nivel de Excelencia en su Proceso de Acreditación Internacional en EFQM, brindando una educación de calidad y reconocimiento en el ámbito local y regional con profesores calificados, garantizando el aprendizaje significativo y la formación integral de los estudiantes a través de la investigación, favoreciendo de manera permanente el fortalecimiento de los valores y habilidades en la comunidad educativa.
        </p>
  
        <p>
          A medida que avanzamos, nuestro compromiso con el desarrollo integral de nuestros estudiantes se fortalece día a día. En el presente, la institución continúa con su misión de garantizar un entorno educativo inclusivo y transformador, donde la educación va más allá de los conocimientos académicos, brindando herramientas para la vida y la formación de ciudadanos responsables y comprometidos con su entorno.
        </p>
        
        <!-- Imagen representativa 4 -->
        <img src="@/assets/imgColvm/img (1).jpg" alt="Compromiso educativo del Colegio Visión Mundial">
  
        <p>
          El Colegio Visión Mundial se ha consolidado como un referente educativo en Montería y su entorno, destacándose por su enfoque integral y su capacidad de adaptación a las necesidades de sus estudiantes, con un claro objetivo de formar líderes responsables y empáticos, preparados para enfrentar los retos del futuro con una visión global y solidaria.
        </p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ResenaHistorica',
  };
  </script>
  
  <style scoped>
  .history {
    text-align: center;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .history h2 {
    color: #003366;
    margin-bottom: 20px;
  }
  
  .history p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .history img {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .history img:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  </style>