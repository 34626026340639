<template>
  <div class="container mt-4">
    <div class="row">
      <!-- Lista de Grados (Responsive) -->
      <div class="col-md-4 mb-4">
        <h5 class="text-primary">Seleccione un grado:</h5>

        <!-- Lista para pantallas grandes -->
        <ul class="list-group d-none d-md-block">
          <li
            v-for="(grado, index) in grados"
            :key="grado"
            class="list-group-item d-flex align-items-center"
            @click="seleccionarGrado(grado)"
            :class="{ active: grado === gradoSeleccionado }"
            style="cursor: pointer;"
          >
            <span
              class="badge rounded-pill me-2"
              style="min-width: 2rem; text-align: center; background-color: var(--color-co); color: var(--color-se);"
            >
              {{ index + (-2) }}°
            </span>
            {{ grado }}
          </li>
        </ul>

        <!-- Select para pantallas pequeñas -->
        <select
          class="form-select my-4 d-block d-md-none"
          v-model="gradoSeleccionado"
          @change="cargarPDFs"
        >
          <option value="" disabled>Seleccione un grado:</option>
          <option v-for="grado in grados" :key="grado" :value="grado">
            {{ grado }}
          </option>
        </select>
      </div>

      <!-- Previsualización y Descarga del PDF -->
      <div class="col-md-8">
        <div v-if="gradoSeleccionado">
          <h5 class="text-primary">
            Lista de útiles escolares para {{ gradoSeleccionado }}
          </h5>
          <div v-if="pdfSeleccionado">
            <iframe
              :src="pdfSeleccionado"
              class="w-100 border responsive-iframe"
            ></iframe>
            <div class="mt-3 d-flex flex-column flex-md-row justify-content-between">
              <a
                :href="pdfSeleccionado"
                target="_blank"
                class="btn btn-outline-primary mb-2 mb-md-0"
              >
                Ver en Google Drive
              </a>
              <a
                :href="cambiarVistaPDF(pdfSeleccionado)"
                target="_blank"
                download
                class="btn btn-outline-success"
              >
                Descargar PDF
              </a>
            </div>
          </div>
          <p v-else class="text-muted">
            No hay archivo disponible para este grado.
          </p>
        </div>
        <p v-else class="text-muted">Seleccione un grado para ver la lista.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      grados: [
        "Prejardín",
        "Jardín",
        "Transición",
        "Primero",
        "Segundo",
        "Tercero",
        "Cuarto",
        "Quinto",
        "Sexto",
        "Séptimo",
        "Octavo",
        "Noveno",
        "Décimo",
        "Undécimo",
      ],
      gradoSeleccionado: "",
      pdfSeleccionado: "",
    };
  },
  methods: {
    seleccionarGrado(grado) {
      this.gradoSeleccionado = grado;
      this.cargarPDFs();
    },
    cargarPDFs() {
      const listasPorGrado = {
        Prejardín: "https://drive.google.com/file/d/1Kzq5sk5QKP_kWGK6-bby6zLmYAPaFSwg/preview",
        Jardín: "https://drive.google.com/file/d/1cGejXG5OZ8RVfPY8-L80f7DG-rD7O8Y9/preview",
        Transición: "https://drive.google.com/file/d/1nrD8PR5GyGmcs35tJGWE72enMVr83mys/preview",
        Primero: "https://drive.google.com/file/d/1Mo-pyYM1OEJRMNdwvr0CNEyK1b3lZz5s/preview",
        Segundo: "https://drive.google.com/file/d/1Pjq4cjXVyI1fpAIVjiItBGBIlIX3RtGj/preview",
        Tercero: "https://drive.google.com/file/d/17KCwdAaw6yVaNCo38yKgEa1HrrAFEdL7/preview",
        Cuarto: "https://drive.google.com/file/d/1qqV7uKI1liDIZo19Cnud8cMCtG8Fkd9Y/preview",
        Quinto: "https://drive.google.com/file/d/1T1FNBEtPKj7FFCK5HOXUskc8hyPBe-fu/preview",
        Sexto: "https://drive.google.com/file/d/12O4X6k93X8haNaynfPDJAJ5Rj3_0y67E/preview",
        Séptimo: "https://drive.google.com/file/d/1V1hJN63HHbjegmiw4paKhQU1E-hCk7hg/preview",
        Octavo: "https://drive.google.com/file/d/1KGNK5bQHI_hOs8sfd86t3zpogGoJwt0F/preview",
        Noveno: "https://drive.google.com/file/d/12J3CTxJYrwsysimdsgPDC11X8P_u7Gg2/preview",
        Décimo: "https://drive.google.com/file/d/1ijZPW6VrafigMclG5XrI1wWUQYWzPNRM/preview",
        Undécimo: "https://drive.google.com/file/d/1UYC88Sx9FADgmgJCy0R7bol30sEH9HXc/preview",
      };
      this.pdfSeleccionado = listasPorGrado[this.gradoSeleccionado] || "";
    },
    cambiarVistaPDF(url) {
      return url.replace("/preview", "/view");
    },
  },
};
</script>

<style scoped>
.text-primary {
  color: var(--color-pr);
  font-weight: bold;
  text-transform: uppercase;
}

.text-secondary {
  color: var(--color-se);
}

.border {
  border-width: 2px;
}

.list-group-item.active {
  background-color: var(--color-pr);
  color: var(--color-co);
  border-color: var(--color-pr);
}

.responsive-iframe {
  height: 80vh;
  max-height: 500px;
}
</style>