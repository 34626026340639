<template>
    <div class="container my-3">
        <div class="container p-1 bg-co rounded">
            <div class="text-center mb-4">
                <h2 class="text-pri mt-2 text-uppercase fw-bold">Evaluación Docente IV Período</h2>
                <hr class="divider">
            </div>
            <img src="@/assets/PerfilDocente/Docentesimg.png" alt="Evaluación Docente" class="img-fluid rounded" />

            <p class="lead p-2 text-justify ">
                Esta evaluación permite a los
                estudiantes ofrecer retroalimentación valiosa sobre el desempeño de sus maestros, contribuyendo así a un
                ambiente educativo más enriquecedor y eficaz. Su participación en esta evaluación es crucial para
                identificar áreas de fortaleza y oportunidades de mejora en la práctica docente, ayudando a garantizar
                que todos los estudiantes reciban una educación de alta calidad.
            </p>
            <h4>Instrucciones</h4>
            <ol class="list-decimal ms-2 text-justify">
                <li><strong>Elija al Docente:</strong> Seleccione el docente que desea evaluar. Asegúrese de tener en
                    cuenta a todos los maestros con los que ha interactuado durante el período.</li>
                <li><strong>Acceso al Formulario:</strong> Una vez que haya elegido al docente, será redirigido a un
                    formulario para completar la evaluación.</li>
                <li><strong>Inicio de Sesión:</strong> Para acceder al formulario, debe ingresar con su correo
                    institucional. Si no utiliza su correo institucional, no podrá completar la evaluación. Asegúrese de
                    estar conectado con su cuenta institucional antes de intentar acceder.</li>
                <li><strong>Complete la Evaluación:</strong> Llene el formulario proporcionando comentarios honestos y
                    constructivos sobre el docente seleccionado. Su feedback es esencial para mejorar la calidad
                    educativa.</li>
            </ol>
            <p class="text-muted mt-4">
                Recuerde que sus respuestas son confidenciales y serán utilizadas únicamente para fines de evaluación y
                mejora. ¡Gracias por su participación!
            </p>
        </div>

        <!-- Acordeón de categorías -->
        <div class="accordion" v-if="isAccordionEnabled" id="docenteAccordion">
            <!-- Preescolar -->
            <div class="accordion-item my-2">
               <h2 class="accordion-header" id="headingPreescolar">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePreescolar" aria-expanded="true" aria-controls="collapsePreescolar">
                        Docentes Visionario - <strong> Preescolar</strong>
                    </button>

                    
                </h2>
                <div id="collapsePreescolar" class="accordion-collapse collapse show"
                    aria-labelledby="headingPreescolar" data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesPreescolar" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h5 class="card-title">{{ docente.nombre }}</h5>

                                    <p class="card-text">Orientador de Grupo: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Primaria -->
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingPrimaria"> 
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePrimaria" aria-expanded="false" aria-controls="collapsePrimaria">
                        Docentes Visionario - <strong> Primaria</strong>
                    </button>
                </h2>
                <div id="collapsePrimaria" class="accordion-collapse collapse" aria-labelledby="headingPrimaria"
                    data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesPrimaria" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h5 class="card-title">{{ docente.nombre }}</h5>
                                    <p class="card-text">Grado: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Media y Secundaria -->
            <div class="accordion-item my-2">
                <h2 class="accordion-header" id="headingMediaSecundaria">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseMediaSecundaria" aria-expanded="false"
                        aria-controls="collapseMediaSecundaria">
                        Docentes Visionario - <strong> Media y Secundaria</strong>
                    </button>
                </h2>
                <div id="collapseMediaSecundaria" class="accordion-collapse collapse"
                    aria-labelledby="headingMediaSecundaria" data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesMediaSecundaria" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h5 class="card-title">{{ docente.nombre }}</h5>
                                    <hr class="divider">

                                    <p class="card-text">Grado: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        
            isAccordionEnabled: false, // Esto controla si el acordeón está habilitado
            docentesPreescolar: [
                { id: 1, nombre: 'Aura Caraballo Argel', grado: 'Pre-jardín', foto: 'AURA.png', formLink: 'https://forms.gle/ckErA1rHXo21sV5B8' },
                { id: 2, nombre: 'Kenia Castillo Bonilla', grado: 'Jardín B', foto: 'KENIA.png', formLink: 'https://forms.gle/vae8i2nJeNqDy9Rx5' },
                { id: 3, nombre: 'Raiza Álvarez Arrieta', grado: 'Transición', foto: 'RAIZA.png', formLink: 'https://forms.gle/j6CMaFS1t7QvYPb26' },
                { id: 4, nombre: 'Paula Figueroa González', grado: 'Docente', foto: 'PAULA.png', formLink: 'https://forms.gle/3PWWuUUD3xcQ3ynp6' },
                { id: 5, nombre: 'Dilson Maza Pernett', grado: 'Docente', foto: 'DILSON.png', formLink: 'https://forms.gle/8HC8ucxS5aibSDo67' },
                { id: 6, nombre: 'Ana Gómez Buelvas', grado: '11A° - Undécimo A', foto: 'ANA.png', formLink: 'https://forms.gle/f2RwgHCaS6CMibS27' },

            ],
            docentesPrimaria: [
                { id: 7, nombre: 'Libia Pernett Galarcio', grado: '1° - Primero', foto: 'LIBIA.png', formLink: 'https://forms.gle/qM95dyQcZqcim5v46' },
                { id: 8, nombre: 'María Vertel González', grado: '2° - Segundo', foto: 'MARIA VERTEL.png', formLink: 'https://forms.gle/1zx7i9egMiwMrZeN8' },
                { id: 9, nombre: 'María Solano Sánchez', grado: '3° - Tercero', foto: 'MARIA SOLANO.png', formLink: 'https://forms.gle/vEV86VF5d15TYwND9' },
                { id: 10, nombre: 'Wendy Pertuz Jiménez', grado: '4° - Cuarto', foto: 'WENDY.png', formLink: 'https://forms.gle/QfjKyHwnTzKN2sGd9' },
                { id: 11, nombre: 'José Hoyos Padilla', grado: '5° - Quinto', foto: 'JOSE.png', formLink: 'https://forms.gle/dTkn3LbxsWuaUDPb7' },
                { id: 12, nombre: 'Dilson Maza Pernett', grado: 'Docente ', foto: 'DILSON.png', formLink: 'https://forms.gle/8HC8ucxS5aibSDo67' },
                { id: 13, nombre: 'Paula Figueroa González', grado: 'Docente ', foto: 'PAULA.png', formLink: 'https://forms.gle/3PWWuUUD3xcQ3ynp6' },
                { id: 14, nombre: 'Shirley Pedroza Banda', grado: '8A° - Octavo A', foto: 'SHIRLEY.png', formLink: 'https://forms.gle/NrTFvoboZcMLshGUA' },
                { id: 15, nombre: 'Karina Guerra Pérez', grado: '7A° - Séptimo A', foto: 'KARINA.png', formLink: 'https://forms.gle/oYWi867WLbkuCnTJ7' },
                { id: 16, nombre: 'Ana Gómez Buelvas', grado: '11A° - Undécimo A', foto: 'ANA.png', formLink: 'https://forms.gle/f2RwgHCaS6CMibS27' },
                { id: 17, nombre: 'Kelly González Ricardo', grado: '9B° - Noveno B', foto: 'KELLY.png', formLink: 'https://forms.gle/t3pFvNmEnVgrMqP88' },

            ],
            docentesMediaSecundaria: [
                { id: 18, nombre: 'Javier Daza Barreto', grado: '6A° - Sexto A', foto: 'JAVIER.png', formLink: 'https://forms.gle/EovWKitNqfDXyPT16' },
                { id: 19, nombre: 'Petrona Altamiranda Sierra', grado: '6B - Sexto B°', foto: 'PETRONA.png', formLink: 'https://forms.gle/yXNN8DHpXJe6K9xp6' },
                { id: 20, nombre: 'Karina Guerra Pérez', grado: '7A° - Séptimo A', foto: 'KARINA.png', formLink: 'https://forms.gle/oYWi867WLbkuCnTJ7' },
                { id: 21, nombre: 'Shirley Pedroza Banda', grado: '8A° - Octavo A', foto: 'SHIRLEY.png', formLink: 'https://forms.gle/NrTFvoboZcMLshGUA' },
                { id: 22, nombre: 'Jean Otero Pantoja', grado: '9A° - Noveno A', foto: 'JEAN.png', formLink: 'https://forms.gle/CNEBrYFKwMGYmqdD7' },
                { id: 23, nombre: 'Kelly González Ricardo', grado: '9B° - Noveno B', foto: 'KELLY.png', formLink: 'https://forms.gle/t3pFvNmEnVgrMqP88' },
                { id: 24, nombre: 'Dioris Matos Osorno', grado: '10A° - Décimo A', foto: 'DIORIS.png', formLink: 'https://forms.gle/9C4EnZEFtwQCzEtZ9' },
                { id: 25, nombre: 'Jesus Rodríguez Prasca', grado: '10B° - Décimo B', foto: 'JESUS.png', formLink: 'https://forms.gle/eK6i8qwYjgeqH1td7' },
                { id: 26, nombre: 'Ana Gómez Buelvas', grado: '11A° - Undécimo A', foto: 'ANA.png', formLink: 'https://forms.gle/f2RwgHCaS6CMibS27' },
                { id: 27, nombre: 'Angelica Arroyo Jiménez', grado: 'Docente', foto: 'ANGELICA.png', formLink: 'https://forms.gle/XyoruiPvYYxU6mKo9' },
                { id: 28, nombre: 'Yency Gonzáles Quintero', grado: 'Docente', foto: 'YENCI.png', formLink: 'https://forms.gle/QA3eQu2LyquYsLXa9' },
                { id: 29, nombre: 'Dilson Maza Pernett', grado: 'Docente', foto: 'DILSON.png', formLink: 'https://forms.gle/8HC8ucxS5aibSDo67' },
                { id: 30, nombre: 'Mauricio Cuadrado Martínez', grado: 'Docente', foto: 'MAURICIO.png', formLink: 'https://forms.gle/pd6CCzcKE64pQ5tZ8' },
                { id: 31, nombre: 'Paula Figueroa González', grado: 'Docente', foto: 'PAULA.png', formLink: 'https://forms.gle/3PWWuUUD3xcQ3ynp6' },
            ],
        };
    },
    methods: {
        redirectToForm(link) {
            window.open(link, '_blank');
        },
        toggleAccordion() {
            this.isAccordionEnabled = !this.isAccordionEnabled;
        },
    },
}
</script>

<style scoped>
.text-pri {
    color: var(--color-se);
    /* Usar color secundario para el título */
}

.text-justify {
    text-align: justify;
}

.lead {
    color: var(--color-se);
    /* Color secundario para el texto destacado */
}

.bg-co {
    background-color: var(--color-co);
    /* Fondo blanco */
}

.p-3 {
    padding: 1rem;
    /* Espaciado interno */
}

.rounded {
    border-radius: .25rem;
    /* Bordes redondeados */
}

.grid-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    justify-items: center;
    /* Centra las tarjetas horizontalmente */
}

.card {
    border-width: 2px;
    border-color: var(--color-se);
    /* Colores de borde */

    cursor: pointer;
    max-width: 300px;
    /* Limita el ancho máximo de las tarjetas */
    background-color: var(--color-co);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    /* Suavizar la transformación */

}



.card-img-top {
    height: 280px;
    object-fit: contain;
}


.card-title {
    color: var(--color-se);
    text-transform: uppercase;
    background-color: var;
    font-weight: bold;
}

.card-text {
    color: var(--color-pr);
    font-weight: bold;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-color: var(--color-pr);
    /* Colores de borde */
    background-color: var(--color-co);
    transform: scale(1.03) rotate(1deg);
    /* Agrandar y rotar ligeramente */

}

@media (max-width: 576px) {
  .accordion-button {
    font-size: 0.9rem; /* Tamaño de fuente más pequeño en dispositivos móviles */
    padding: 0.5rem; /* Reduce el padding para mejorar el ajuste en pantallas pequeñas */
    text-align: left; /* Alineación a la izquierda para el contenido */
  }

  .accordion-header {
    padding: 0; /* Elimina padding extra en el header */
  }
}

.accordion-button {
    background-color: var(--color-co);
    color: var(--color-pr);
    border: 1px solid var(--color-pr);
    text-transform: uppercase;
    transition: background-color 0.2s, box-shadow 0.2s;
    /* Transición para suavizar el cambio de color y sombra */
}

.accordion-button:not(.collapsed) {
    background-color: var(--color-pr);
    color: var(--color-co);
    font-weight: bold;
    text-transform: uppercase;
}

.accordion-button:focus,
/* Para el estado de enfoque (cuando se hace clic) */
.accordion-button:active {
    /* Para el estado activo (cuando se mantiene presionado) */
    box-shadow: 0 0 0px 0px rgba(0, 0, 255, 0.5);
    /* Resplandor azul */
}

.accordion-button::after {
    filter: invert(1);
}
</style>